<template>
  <div>
    <div class="box-outline p-10 mt-4">
      <p class="is-subtitle-small is-dark-gray is-bold">Messages</p>
      <Loading v-if="initialLoading" />
      <div
        v-if="!initialLoading"
        ref="chatContainer"
        class="chat-container mt-4"
      >
        <div v-if="!messages || !messages.length">
          <div class="columns is-vcentered mt-5">
            <div class="column has-text-centered">
              <img
                src="@/assets/img/misc/contact_us_illustration.svg"
                width="180px"
              />
              <div class="is-secondary is-subtitle-small is-bold mt-2">
                Have any questions?
              </div>
              <div>Feel free to reach out to us</div>
            </div>
          </div>
          <hr />
        </div>
        <div
          v-for="(message, index) in messages"
          :key="index"
          style="width: 100%; display: contents"
        >
          <p
            v-if="message.text"
            :class="[
              prespective === message.sender ? 'from-me' : 'from-them',
              isEmoji(message.text) ? 'no-tail emoji' : '',
            ]"
          >
            {{ message.text }}
          </p>
          <div
            v-if="message.attachments[0]"
            :class="
              prespective === message.sender
                ? 'has-text-right'
                : 'has-text-left'
            "
            class="mb-2"
          >
            <div
              v-for="(attachment, index) in message.attachments"
              :key="index"
              class="mt-2"
            >
              <a :href="attachment.file" target="_blank">
                <b-button icon-left="download" type="is-info">
                  {{ attachment.filename }}
                </b-button>
              </a>
            </div>
          </div>
          <span
            :class="
              prespective === message.sender
                ? 'has-text-right'
                : 'has-text-left'
            "
            class="is-small"
          >
            {{ message.createdAt | date("time-date") }}
          </span>
        </div>
      </div>
      <div v-if="isfileUplading" class="columns is-vcentered">
        <div class="column is-narrow has-text-centered has-text-success">
          <b-icon class="mt-2 loading-animation" icon="loading"></b-icon>
        </div>
        <div class="column is-narrow mx-0 px-0">
          <span class="is-small"> Uploading files, please wait... </span>
        </div>
      </div>
      <div v-if="dropFiles.length && !isfileUplading" class="columns">
        <div class="column">
          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column">
          <b-input
            v-model="inputText"
            placeholder="Type message to send.."
            :disabled="sending || initialLoading"
            @keyup.native.enter="sendMessage()"
            expanded
          ></b-input>
        </div>
        <div
          v-if="sending && !isfileUplading"
          class="column is-narrow has-text-centered"
        >
          <b-icon
            class="loading-animation"
            type="is-info"
            icon="loading"
          ></b-icon>
          <p class="is-small has-text-success">SENDING</p>
        </div>
        <div class="column is-narrow has-text-centered">
          <span @click="sendEmoji('👍🏻')" class="reaction has-pointer">👍🏻</span>
          <span @click="sendEmoji('♥️')" class="reaction has-pointer ml-3"
            >♥️</span
          >
          <span @click="sendEmoji('🤩')" class="reaction has-pointer ml-3"
            >🤩</span
          >
        </div>
        <div
          class="column is-narrow has-text-centered has-text-info has-pointer"
        >
          <b-upload v-model="dropFiles" multiple class="has-pointer">
            <b-icon icon="paperclip"></b-icon>
          </b-upload>
        </div>
        <div
          @click="sendMessage"
          class="column is-narrow has-text-centered has-text-success has-pointer"
        >
          <b-icon icon="send"></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading.vue";
import api from "../services/dataService";

export default {
  name: "ChatBox",
  components: { Loading },
  props: {
    prespective: String,
  },
  data() {
    return {
      getMessagesTimeout: undefined,
      initialLoading: true,
      loading: false,
      isfileUplading: false,
      sending: false,
      messages: undefined,
      inputText: "",
      dropFiles: [],
    };
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
  },
  async created() {
    await this.getChatMessages();
    this.getMessagesTimeout = setInterval(() => {
      this.getChatMessages();
    }, 15000);
    this.initialLoading = false;
  },
  beforeDestroy() {
    clearInterval(this.getMessagesTimeout);
    this.getMessagesTimeout = undefined;
  },
  updated() {
    this.scrollToLastMessage();
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    async getChatMessages() {
      await api
        .get(`/common/message?orderId=${this.orderId}&type=${this.prespective}`)
        .then((res) => {
          this.messages = res.data;
        });
    },
    async sendMessage() {
      if (this.inputText == "" && this.dropFiles.length < 1) {
        return;
      }
      if (this.sending || this.initialLoading) {
        return;
      }
      this.sending = true;
      const formDataRequest = new FormData();
      formDataRequest.append("sender", this.prespective);
      formDataRequest.append("text", this.inputText);
      formDataRequest.append("orderId", this.orderId);

      if (this.dropFiles.length > 0) {
        this.isfileUplading = true;
        for (let i = 0; i < this.dropFiles.length; i++) {
          formDataRequest.append("attachments", this.dropFiles[i]);
        }
      }

      await api.post(`/common/message`, formDataRequest).then(async () => {
        this.inputText = "";
        this.dropFiles = [];
        this.getChatMessages();
      });
      this.sending = false;
      this.isfileUplading = false;
    },
    async sendEmoji(emoji) {
      if (this.sending || this.initialLoading) {
        return;
      }
      this.sending = true;
      const formDataRequest = new FormData();
      formDataRequest.append("sender", this.prespective);
      formDataRequest.append("text", emoji);
      formDataRequest.append("orderId", this.orderId);
      await api.post(`/common/message`, formDataRequest).then(async () => {
        this.getChatMessages();
      });
      this.sending = false;
    },
    isEmoji(text) {
      const emojis = ["👍🏻", "♥️", "🤩"];
      return emojis.includes(text);
    },
    scrollToLastMessage() {
      const container = this.$refs.chatContainer;
      container.scrollTop = container.scrollHeight;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.chat-container {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
  padding: 0.5rem 1.5rem;
  height: 60vh;
  overflow-y: auto;
  background-image: radial-gradient(#bdbdbd 0.5px, #ffffff 0.5px);
  background-size: 10px 10px;
}

.chat-container p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 60%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.chat-container p::before,
.chat-container p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-me {
  align-self: flex-end;
  background-color: $secondary-color;
  color: #fff;
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid $secondary-color;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.from-me ~ p.from-me {
  margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:last-child {
  margin-bottom: 0.5rem;
}

p.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
  content: none;
}

.reaction {
  font-size: 19px;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}

@media screen and (max-width: 800px) {
  .chat-container {
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.25rem 0.875rem;
  }

  .chat-container p {
    margin: 0.5rem 0;
  }
}
</style>

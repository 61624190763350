<template>
  <div>
    <!-- Colour Palettes -->
    <img
      v-if="image == 'ColourPalettes/1.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/2.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/3.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/4.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/5.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/6.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/7.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />
    <img
      v-if="image == 'ColourPalettes/8.svg'"
      src="@/assets/img/responses/ColourPalettes/1.svg"
    />

    <img
      v-if="image == 'ColourPalettes/aquas.svg'"
      src="@/assets/img/responses/ColourPalettes/aquas.svg"
    />

    <img
      v-if="image == 'ColourPalettes/blues.svg'"
      src="@/assets/img/responses/ColourPalettes/blues.svg"
    />

    <img
      v-if="image == 'ColourPalettes/brandeeq_s_choice.svg'"
      src="@/assets/img/responses/ColourPalettes/brandeeq_s_choice.svg"
    />

    <img
      v-if="image == 'ColourPalettes/dark_neutrals.svg'"
      src="@/assets/img/responses/ColourPalettes/dark_neutrals.svg"
    />

    <img
      v-if="image == 'ColourPalettes/greens.svg'"
      src="@/assets/img/responses/ColourPalettes/greens.svg"
    />

    <img
      v-if="image == 'ColourPalettes/light_neutrals.svg'"
      src="@/assets/img/responses/ColourPalettes/light_neutrals.svg"
    />

    <img
      v-if="image == 'ColourPalettes/oranges.svg'"
      src="@/assets/img/responses/ColourPalettes/oranges.svg"
    />

    <img
      v-if="image == 'ColourPalettes/pinks.svg'"
      src="@/assets/img/responses/ColourPalettes/pinks.svg"
    />

    <img
      v-if="image == 'ColourPalettes/purples.svg'"
      src="@/assets/img/responses/ColourPalettes/purples.svg"
    />

    <img
      v-if="image == 'ColourPalettes/reds.svg'"
      src="@/assets/img/responses/ColourPalettes/reds.svg"
    />

    <img
      v-if="image == 'ColourPalettes/yellows.svg'"
      src="@/assets/img/responses/ColourPalettes/yellows.svg"
    />

    <!-- Type Faces -->

    <img
      v-if="image == 'TypeFaces/1.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/2.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/3.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/4.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/5.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/6.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/7.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />
    <img
      v-if="image == 'TypeFaces/8.svg'"
      src="@/assets/img/responses/TypeFaces/1.svg"
    />

    <!-- Logo Types -->
    <img
      v-if="image == 'LogoTypes/abstract.svg'"
      src="@/assets/img/responses/LogoTypes/abstract.svg"
    />
    <img
      v-if="image == 'LogoTypes/combination.svg'"
      src="@/assets/img/responses/LogoTypes/combination.svg"
    />
    <img
      v-if="image == 'LogoTypes/emblem.svg'"
      src="@/assets/img/responses/LogoTypes/emblem.svg"
    />
    <img
      v-if="image == 'LogoTypes/lettermark.svg'"
      src="@/assets/img/responses/LogoTypes/lettermark.svg"
    />
    <img
      v-if="image == 'LogoTypes/mascot.svg'"
      src="@/assets/img/responses/LogoTypes/mascot.svg"
    />
    <img
      v-if="image == 'LogoTypes/wordmark.svg'"
      src="@/assets/img/responses/LogoTypes/wordmark.svg"
    />
    <img
      v-if="image == 'LogoTypes/pictorial.svg'"
      src="@/assets/img/responses/LogoTypes/pictorial.svg"
    />
  </div>
</template>

<script>
export default {
  name: "ResponseImageViewer",
  props: {
    image: String,
  },
};
</script>

<style lang="scss" scoped></style>
